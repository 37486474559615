import { Competitor } from "../model/Competitor";

interface Props {
  Competitor: Competitor
}

const CompetitorDetail = (props: Props) => {
  const competitor = props.Competitor;

  switch (competitor.Kind) {
    case 'Individual':
      return <>
        <td>
          {competitor.Athlete.FirstName}
        </td>
        <td>
          {competitor.Athlete.LastName}
        </td>
        <td>
          {competitor.Athlete.Born}
        </td>
      </>;

    case 'MonkTeam':
      return <>
        <td>
          {competitor.Athlete1.FirstName}
          <br />
          {competitor.Athlete2.FirstName}
        </td>
        <td>
          {competitor.Athlete1.LastName}
          <br />
          {competitor.Athlete2.LastName}
        </td>
        <td>
          {competitor.Athlete1.Born}
          <br />
          {competitor.Athlete2.Born}
        </td>
      </>;

    case 'Team':
      return <>
        <td>
          {competitor.Athlete1.Start}
          <br />
          {competitor.Athlete2.Start}
          <br />
          {competitor.Athlete3?.Start}
          <br />
          {competitor.Athlete4?.Start}
        </td>
        <td>
          {competitor.Athlete1.FirstName}
          <br />
          {competitor.Athlete2.FirstName}
          <br />
          {competitor.Athlete3?.FirstName}
          <br />
          {competitor.Athlete4?.FirstName}
        </td>
        <td>
          {competitor.Athlete1.LastName}
          <br />
          {competitor.Athlete2.LastName}
          <br />
          {competitor.Athlete3?.LastName}
          <br />
          {competitor.Athlete4?.LastName}
        </td>
        <td>
          {competitor.Athlete1.Born}
          <br />
          {competitor.Athlete2.Born}
          <br />
          {competitor.Athlete3?.Born}
          <br />
          {competitor.Athlete4?.Born}
        </td>
      </>;
  }
};

export default CompetitorDetail;