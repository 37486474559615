import { Card, CardBody, CardHeader } from "reactstrap";
import { CompetitionDay } from "../../data/StartLIstData";
import { StartList } from "../../model/StartList";
import StartListTable from "./StartListTable";

interface Props {
  CurrentTime: Date,
  StartList: StartList
}

const StartListCard = (props: Props) => {
  return <Card>
    <CardHeader>
      <h2>Start list: {props.StartList.Discipline}</h2>
    </CardHeader>
    <CardBody>
      <StartListTable Startist={props.StartList} CurrentTime={props.CurrentTime} />
    </CardBody>
  </Card>;
}

export default StartListCard;