import { Table } from "reactstrap";
import { StartList } from '../../model/StartList';
import date from 'date-and-time';
import CompetitorDetail from "../CompetitorDetail";

interface Props {
  CurrentTime: Date,
  Startist: StartList
}

const StartListTable = (props: Props) => {
  const pad = (input: number) => String(input).padStart(2, '0');
  const startListRows = props.Startist.StartListRows
    .filter(x => date.subtract(x.StartTime, props.CurrentTime).toSeconds() > 0)
    .map(x =>
      <>
        <tr>
          <th scope="row">
            {`${pad(x.StartTime.getHours())}:${pad(x.StartTime.getMinutes())}`}
          </th>
          <CompetitorDetail Competitor={x.Competitor} />
          <td>
            {x.Nation}
          </td>
        </tr>
      </>
    );

  return <Table striped>
    <thead>
      <tr>
        <th>
          Start time
        </th>
        <th>
          Start
        </th>
        <th>
          First Name
        </th>
        <th>
          Last Name
        </th>
        <th>
          Born
        </th>
        <th>
          Nation
        </th>
      </tr>
    </thead>
    <tbody>
      {startListRows}
    </tbody>
  </Table>
};

export default StartListTable;