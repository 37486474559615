import { Table } from 'reactstrap';
import { DisciplineResults } from '../../model/Results'
import CompetitorDetail from '../CompetitorDetail';

interface Props {
  Results: DisciplineResults
}

const ResultsTable = (props: Props) => {
  const resultRows = props.Results.Results.map(x =>
    <tr>
      <th scope='row'>{x.Rank}.</th>
      <CompetitorDetail Competitor={x.Competitor} />
      <td>{x.Nation}</td>
      <td>{x.A.map(a => <>{a}<br /></>)}</td>
      <td>{x.B.map(b => <>{b}<br /></>)}</td>
      <td>{x.Finish}</td>
      <td>{x.Deviation}</td>
      <td>{x.AtFinish}</td>
      <td>{x.Points}</td>
    </tr>);

  return <Table hover>
    <thead>
      <tr>
        <th>
          Rank
        </th>
        <th>
          Start
        </th>
        <th>
          First Name
        </th>
        <th>
          Last Name
        </th>
        <th>
          Born
        </th>
        <th>
          Nation
        </th>
        <th>
          A
        </th>
        <th>
          B
        </th>
        <th>
          Finish
        </th>
        <th>
          Dev.
        </th>
        <th>
          At finish
        </th>
        <th>
          Points
        </th>
        {/* <th>
          CMAS
        </th> */}
      </tr>
    </thead>
    <tbody>
      {resultRows}
    </tbody>
  </Table>
};

export default ResultsTable;