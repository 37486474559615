import { Card, CardBody, CardHeader } from "reactstrap";
import ResultsTable from "./ResultsTable";
import { DisciplineResults } from '../../model/Results';

export interface Props {
  Results: DisciplineResults
};

const ResultsCard = (props: Props) => {

  return <>
    <Card>
      <CardHeader>
        <h1>Results: {props.Results.Discipline}</h1>
      </CardHeader>
      <CardBody>
        <ResultsTable Results={props.Results} />
      </CardBody>
    </Card>
  </>;
};

export default ResultsCard;